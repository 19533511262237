

































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { calculateClaimsRequirementPremium,
  getProductPageBeforePriceTabHelper,
  onCurrencyFocus, recursiveFillData,
  transformNumber, copyObject } from '@/helper/functions';
import PramieTable from '@/views/components/bauleistung-insurance/pramie-table.vue';
import { calculatePramieergebnisseTarifkalkulationItem, calculatePramieTableItem, getDiscountsBonuses, getPramieergebnisseTarifkalkulationFactor } from '@/helper/priceCalculator/bauleistung-calculator';
import { saveZusammenfassungPDF } from '@/helper/handle-pdf';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import popUpModal from '@/components/form/pop-up-modal.vue';
// import priceStatusIcon from '@/components/form/price-status-icon.vue';

const resultsOfTariffCalculationItem = {
  pos: 'SB-Tarif SB',
  /* Selbstbehalt */
  Selbstbehalt: null,
  /* Tarifprämie */
  TarifPramie: null,
  /* Dauernachlass */
  Dauernachlass: 0,
  /* Laufzeit */
  Laufzeit: null,
  /* Generalvertragsnachlasses */
  Generalvertragsnachlasses: 0,
  /* SVR */
  SVR: 0,
  /* Betrachtungszeitraum */
  Betrachtungszeitraum: null,
  /* SQ */
  SQ: null,
  /* Nettoisierung */
  Nettoisierung: 0,
  /* Prämie inkl. tariflicher Nachlässe */
  Pramie: null,
  showInPdf: true,
};

const promotionalRewardItem = {
  pos: '',
  SB: null,
  PremiumIncludingTariffDiscounts: null,
  PremiumIncludingRiskAdjustmentFactor: null,
  PremiumIncludingMarketFactor: 0,
  NetOfferPremium: null,
  NetOfferPremiumRate: null,
  GrossOfferPremium: null,
  PremiumNetRentalTurnover: null,
};

export default Vue.extend({
  name: 'price-card',
  mixins: [dataChangeMixin],
  components: {
    popUpModal,
    // priceStatusIcon,
    FormSwitch,
    DatePicker,
    PramieTable,
    xmlStatusIcon,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'processSavingData',
    'productId',
    'priceData',
    'AMSAPISendStatus',
    'insuranceFormsApiData',
  ],

  watch: {
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    priceData: {
      handler() {
        this.onNextClick();
        this.getPriceDataFromApi();
      },
      deep: true,
    },
    processSavingData(value: boolean) {
      if (this.insuranceData && this.insuranceData.spreadsheetData?.SelbstbeteiligungNeubauleistung) {
        this.setType();
        this.setTablesData();
      }
      this.setPramieValues();
      this.getPriceDataFromApi();
    },
    zusatzlicherVorschadenfaktorСoefficient() {
      this.calculatePramieTables();
      this.calculatePramieTablesAltbau();
    },
    ergebnisseTarifkalkulationTarif: {
      handler(newValue, oldValue) {
        calculatePramieergebnisseTarifkalkulationItem(this.ergebnisseTarifkalkulationItems[0]);
        this.getPriceDataItemFromApi('tarif', 0);
        this.promotionalRewards[0].PremiumIncludingTariffDiscounts = this.summNeubau(0);
      },
      deep: true,
    },
    ergebnisseTarifkalkulationVariant1: {
      handler(newValue, oldValue) {
        calculatePramieergebnisseTarifkalkulationItem(this.ergebnisseTarifkalkulationItems[1]);
        this.getPriceDataItemFromApi('variant1', 1);
        this.promotionalRewards[1].PremiumIncludingTariffDiscounts = this.summNeubau(1);
      },
      deep: true,
    },
    ergebnisseTarifkalkulationVariant2: {
      handler(newValue, oldValue) {
        calculatePramieergebnisseTarifkalkulationItem(this.ergebnisseTarifkalkulationItems[2]);
        this.getPriceDataItemFromApi('variant2', 2);
        this.promotionalRewards[2].PremiumIncludingTariffDiscounts = this.summNeubau(2);
      },
      deep: true,
    },
    ergebnisseTarifkalkulationVariant3: {
      handler(newValue, oldValue) {
        calculatePramieergebnisseTarifkalkulationItem(this.ergebnisseTarifkalkulationItems[3]);
        this.getPriceDataItemFromApi('variant3', 3);
        this.promotionalRewards[3].PremiumIncludingTariffDiscounts = this.summNeubau(3);
      },
      deep: true,
    },
    discountsBonuses: {
      handler(newValue, oldValue) {
        this.calculatePramieTables();
      },
      deep: true,
    },
    discountsBonusesAltbau: {
      handler(newValue, oldValue) {
        this.calculatePramieTablesAltbau();
      },
      deep: true,
    },
    calculatePramieTablesAltbau: {
      handler(newValue, oldValue) {
        this.calculatePramieTablesAltbau();
      },
      deep: true,
    },
  },

  data(): any {
    return {
      backUrl: 'individual-insurance-risk',
      isAbnType: false,
      isAbuType: false,
      modalOpen: false,
      modalBody: '',
      modalTitle: '',
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: 0,
        versicherungssumme371: 0,
        produkt: 'Bauleistung',
        nettoisiert: '',
        steuerland: 'Deutschland',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      /* Ergebnisse Tarifkalkulation */
      ergebnisseTarifkalkulationItems: [
        {
          ...resultsOfTariffCalculationItem,
          pos: 'SB-Tarif SB',
          priceKey: 'tarif',
        },
        {
          ...resultsOfTariffCalculationItem,
          pos: 'SB-Variante 1',
          priceKey: 'variant1',
        },
        {
          ...resultsOfTariffCalculationItem,
          pos: 'SB-Variante 2',
          priceKey: 'variant2',
        },
        {
          ...resultsOfTariffCalculationItem,
          pos: 'SB-Variante 3',
          priceKey: 'variant3',
        },
      ],
      deductibleVariantsCssClasses: {
        0: '',
        1: 'table-variant-1',
        2: 'table-variant-2',
        3: 'table-variant-3',
      },
      vorschadenhistorieSelect: false,
      vorschadenhistorie: {
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: null,
        Betrachtungszeitraum: '',
        Zielschadenquote: null,
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: 0,
        Begrundung: '',
      },
      ErgebnisRisikoadjustments: {
        Risikoadjustmentfaktor: '',
      },
      SkillNachlasseUndZuschlage: {
        SkillNachlassTarifVariante: {
          pos: 'Skill-Nachlass Tarif Variante',
          Nachlasse: 0,
          Zuschlage: 0,
          Begrundung: '',
        },
        SkillNachlassSBVarianteItems: [
          {
            pos: 'Skill-Nachlass SB-Variante 1',
            Nachlasse: 0,
            Zuschlage: 0,
          },
          {
            pos: 'Skill-Nachlass SB-Variante 2',
            Nachlasse: 0,
            Zuschlage: 0,
          },
          {
            pos: 'Skill-Nachlass SB-Variante 3',
            Nachlasse: 0,
            Zuschlage: 0,
          },
        ],
      },
      SkillNachlasseUndZuschlageAltbau: {
        SkillNachlassTarifVariante: {
          pos: 'Skill-Nachlass Tarif Variante',
          Nachlasse: null,
          Zuschlage: null,
          Begrundung: '',
        },
        SkillNachlassSBVarianteItems: [
          {
            pos: 'Skill-Nachlass SB-Variante 1',
            Nachlasse: null,
            Zuschlage: null,
          },
          {
            pos: 'Skill-Nachlass SB-Variante 2',
            Nachlasse: null,
            Zuschlage: null,
          },
          {
            pos: 'Skill-Nachlass SB-Variante 3',
            Nachlasse: null,
            Zuschlage: null,
          },
        ],
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
        notes: '',
      },
      promotionalRewards: [
        {
          pos: 'Tarif-SB',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 1',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 2',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 3',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
      ],
      newConstructionPerformanceItems: [
        {
          pos: 'Tarif-SB',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 1',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 2',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 3',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
      ],
      erstrisikosummenItems: [
        {
          pos: 'Tarif-SB',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 1',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 2',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 3',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
      ],
      subsequentLiability: [
        {
          pos: 'Tarif-SB',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 1',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 2',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 3',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
      ],
      fire: [
        {
          pos: 'Tarif-SB',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 1',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 2',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
        {
          pos: 'SB-Variante 3',
          SB: null,
          PremiumIncludingTariffDiscounts: null,
          PremiumIncludingRiskAdjustmentFactor: null,
          PremiumIncludingMarketFactor: 0,
          NetOfferPremium: null,
          NetOfferPremiumRate: null,
          GrossOfferPremium: null,
          PremiumNetRentalTurnover: null,
        },
      ],
      oldBuilding5155: [
        {
          ...copyObject(promotionalRewardItem),
          pos: 'Angebotsprämie Altbau',
        },
      ],
      oldBuilding5180: [
        {
          ...copyObject(promotionalRewardItem),
          pos: 'Angebotsprämie Altbau',
        },
      ],
      oldBuilding5181: [
        {
          ...copyObject(promotionalRewardItem),
          pos: 'Angebotsprämie Altbau',
        },
      ],
      oldBuilding6155: [
        {
          ...copyObject(promotionalRewardItem),
        },
      ],
      pdfStyles: false,
      hinweiseZumBeitrag: '',
    };
  },
  computed: {
    altbauPremiums(): any {
      return this.insuranceData && this.insuranceData.spreadsheetData?.altbauPremiums;
    },
    discountsBonuses(): Array<number> {
      const resultData: Array<number> = [];
      resultData.push(
        getDiscountsBonuses(this.SkillNachlasseUndZuschlage.SkillNachlassTarifVariante),
      );
      this.SkillNachlasseUndZuschlage.SkillNachlassSBVarianteItems.forEach((itm) => {
        resultData.push(getDiscountsBonuses(itm));
      });
      return resultData;
    },
    discountsBonusesAltbau(): Array<number> {
      const resultData: Array<number> = [];
      resultData.push(
        getDiscountsBonuses(this.SkillNachlasseUndZuschlageAltbau.SkillNachlassTarifVariante),
      );
      this.SkillNachlasseUndZuschlageAltbau.SkillNachlassSBVarianteItems.forEach((itm) => {
        resultData.push(getDiscountsBonuses(itm));
      });
      return resultData;
    },

    hasPriceFormData(): boolean {
      return (this.insuranceData.priceFormData !== null && this.insuranceData.priceFormData !== undefined);
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    // Schadenbedarfsprämie
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
    selectedSubsequentLiability(): boolean {
      return this.insuranceData?.spreadsheetData?.Nachhaftung?.MitversicherungNachhaftung === 'Ja' ||
        this.insuranceData?.spreadsheetData?.Nachhaftung?.MitversicherungNachhaftung === 'Optional';
    },
    selectedFire(): boolean {
      return this.insuranceData?.spreadsheetData?.MitversicherungVonFeuer?.MitversicherungVonFeuer === 'Ja' ||
        this.insuranceData?.spreadsheetData?.MitversicherungVonFeuer?.MitversicherungVonFeuer === 'Optional';
    },
    selectedOldBuilding5155(): boolean {
      const value = this.insuranceData?.spreadsheetData?.Altbau?.RisikoItems?.Mitversicherung?.risiko1;
      return value === 'Ja' || value === 'Optional';
    },
    selectedOldBuilding6155(): boolean {
      return this.insuranceData?.spreadsheetData?.AltbauNachAZ6155?.value === 'Ja' ||
        this.insuranceData?.spreadsheetData?.AltbauNachAZ6155?.value === 'Optional';
    },
    selectedOldBuilding5180(): boolean {
      const value = this.insuranceData?.spreadsheetData?.Altbau?.RisikoItems?.Mitversicherung?.risiko2;
      return value === 'Ja' || value === 'Optional';
    },
    selectedOldBuilding5181(): boolean {
      const value = this.insuranceData?.spreadsheetData?.Altbau?.RisikoItems?.Mitversicherung?.risiko3;
      return value === 'Ja' || value === 'Optional';
    },
    avgRisk(): number {
      if (this.insuranceData.riskFactorData && this.insuranceData.riskFactorData.ergebnisDerBewertung) {
        return this.insuranceData.riskFactorData.ergebnisDerBewertung.GesamtergebnisGemabRisikofaktorenbewertung;
      }
      return 1;
    },
    risikofactor(): number {
      if (this.insuranceData.riskFactorData && this.insuranceData.riskFactorData.sonstigesBesichtigungObj) {
        return Number(this.insuranceData.riskFactorData.sonstigesBesichtigungObj.value);
      }
      return 1;
    },
    zusatzlicherVorschadenfaktorСoefficient(): number {
      return (100 - this.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100;
    },
    oldBuildingData(): Array<any> {
      const [oldBuilding5155] = this.oldBuilding5155;
      const [oldBuilding5180] = this.oldBuilding5180;
      const [oldBuilding5181] = this.oldBuilding5181;

      return [
        oldBuilding5155,
        oldBuilding5180,
        oldBuilding5181,
      ];
    },
    ergebnisseTarifkalkulationTarif(): any {
      return this.ergebnisseTarifkalkulationItems[0];
    },
    ergebnisseTarifkalkulationVariant1(): any {
      return this.ergebnisseTarifkalkulationItems[1];
    },
    ergebnisseTarifkalkulationVariant2(): any {
      return this.ergebnisseTarifkalkulationItems[2];
    },
    ergebnisseTarifkalkulationVariant3(): any {
      return this.ergebnisseTarifkalkulationItems[3];
    },
    ergebnisseTarifkalkulationFactors(): Array<number> {
      const resultData: Array<number> = [];
      this.ergebnisseTarifkalkulationItems.forEach((itm) => {
        resultData.push(getPramieergebnisseTarifkalkulationFactor(itm));
      });
      return resultData;
    },
    baukombiSurchargeСoefficient(): number {
      if (this.insuranceData?.basicData?.vertragsart === 'Baukombi') {
        return 1.2;
      }
      return 1;
    },
    vertragsartUmsatzvertragOrAnmeldevertrag(): boolean {
      const validVertragsarts = ['ABN-Umsatzvertrag', 'ABN-Anmeldevertrag', 'ABU-Umsatzvertrag', 'ABU-Anmeldevertrag'];
      return validVertragsarts.includes(this.insuranceData?.basicData?.vertragsart);
    },
    altbauIndividuelleSelected(): boolean {
      return this.insuranceData?.spreadsheetData?.Altbau?.IndividuelleRisikoinformationenZumAltbau?.value === 'Ja';
    },
    oldBuilding5155NetOfferPremium(): number {
      let result = 0;
      if (this.altbauIndividuelleSelected) {
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5155?.items?.AltbauIndividualA?.VSinEUR ?? 0);
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5155?.items?.AltbauIndividualB?.VSinEUR ?? 0);
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5155?.items?.AltbauIndividualC?.VSinEUR ?? 0);
      } else {
        result = transformNumber(this.insuranceData?.spreadsheetData?.Altbau?.RisikoItems?.Versicherungssumme?.risiko1 ?? 0);
      }
      return result;
    },
    oldBuilding5180NetOfferPremium(): number {
      let result = 0;
      if (this.altbauIndividuelleSelected) {
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5180?.items?.AltbauIndividualA?.VSinEUR ?? 0);
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5180?.items?.AltbauIndividualB?.VSinEUR ?? 0);
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5180?.items?.AltbauIndividualC?.VSinEUR ?? 0);
      } else {
        result = transformNumber(this.insuranceData?.spreadsheetData?.Altbau?.RisikoItems?.Versicherungssumme?.risiko2 ?? 0);
      }
      return result;
    },
    oldBuilding5181NetOfferPremium(): number {
      let result = 0;
      if (this.altbauIndividuelleSelected) {
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5181?.items?.AltbauIndividualA?.VSinEUR ?? 0);
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5181?.items?.AltbauIndividualB?.VSinEUR ?? 0);
        result += transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5181?.items?.AltbauIndividualC?.VSinEUR ?? 0);
      } else {
        result = transformNumber(this.insuranceData?.spreadsheetData?.Altbau?.RisikoItems?.Versicherungssumme?.risiko3 ?? 0);
      }
      return result;
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  methods: {
    fillOldBuildingPremiums(category: string, targetArray: any[]) {
      if (targetArray.length > 1) {
        targetArray.splice(1);
      }
      const premiums = this.altbauPremiums[category];

      Object.keys(premiums).forEach((key) => {
        if (Object.keys(premiums[key]).length > 0) {
          targetArray.push({
            ...copyObject(promotionalRewardItem),
            pos: `Objekt ${key.slice(-1)}`,
            SB: premiums[key].SB,
            PremiumIncludingTariffDiscounts: premiums[key].Betrag,
          });
        }
      });
    },

    fillOldBuilding5155() {
      this.fillOldBuildingPremiums('AltbauNachAZ5155', this.oldBuilding5155);
    },

    fillOldBuilding5180() {
      this.fillOldBuildingPremiums('AltbauNachAZ5180', this.oldBuilding5180);
    },

    fillOldBuilding5181() {
      this.fillOldBuildingPremiums('AltbauNachAZ5181', this.oldBuilding5181);
    },
    onCurrencyFocus,
    openModal(body: string) {
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalOpen = false;
    },
    getXmlBtnIsDisable(index: string) {
      if (this.AMSAPISendStatus !== null) {
        const status = this.AMSAPISendStatus[index];
        if (status) {
          return (!status.dataUpdated && status.statusSuccess);
        }
      }
      return false;
    },
    /* queueGetPrice(variantPrice: string) {
      this.$emit('add-to-queue', 'getPriceUseId', variantPrice);
      this.onNextClick();
    }, */
    queueDownloadBigXML(i) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onNextClick);
      this.$emit('add-to-queue', 'downloadBigXML', this.getVariantFromIndex(i));
      this.onNextClick();
    },
    queueSendBigXML(i) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onNextClick);
      this.$emit('add-to-queue', 'sendBigXML', this.getVariantFromIndex(i));
      this.onNextClick();
    },
    getVariantFromIndex(index) {
      switch (index) {
        case 0:
          return 'tarif';
        case 1:
          return 'variant1';
        case 2:
          return 'variant2';
        case 3:
          return 'variant3';
        default:
          return 'tarif';
      }
    },
    updateItem({ arrayName, updatedArray }) {
      // Update the corresponding array
      this[arrayName] = updatedArray;
    },
    queueDownloadPDF() {
      this.$emit('add-to-queue', 'downloadPDF');
      this.onNextClick();
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Bauleistung-Preis-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    goToBackUrl() {
      const routeName = getProductPageBeforePriceTabHelper(this.insuranceKey);

      this.$emit('go-back-tab', routeName, 'insuranceForms', 3);
    },
    setType() {
      const calcFormType = this.insuranceData?.basicData?.vertragsart;

      if (calcFormType.indexOf('ABU') !== -1) {
        this.isAbuType = true;
      }

      if (calcFormType.indexOf('ABN') !== -1 || calcFormType === 'Baukombi') {
        this.isAbnType = true;
      }
    },

    setTablesData() {
      // column C
      const data = this.isAbnType ?
        this.insuranceData.spreadsheetData.SelbstbeteiligungNeubauleistung :
        this.insuranceData.spreadsheetData.Selbstbeteiligung;

      const dataToInsert = Object.entries<any>(data).map((itm) => itm[1]?.GenerelleSelbstbeteiligung);

      this.setSelbstbeteiligungNeubauleistungData('SB', dataToInsert);
      this.setSubsequentLiabilityData('SB', dataToInsert);
      this.setFireData('SB', dataToInsert);
      this.setErstrisikosummenItems('SB', dataToInsert);
      this.setPromotionalRewardsItems('SB', dataToInsert);
    },

    setSelbstbeteiligungNeubauleistungData(columnName: string, data: any) {
      const dataIsArray = data instanceof Array;

      this.newConstructionPerformanceItems.forEach((item, i) => {
        this.newConstructionPerformanceItems[i][columnName] = dataIsArray ? data[i] : data;
      });
    },
    setSubsequentLiabilityData(columnName: string, data: Array<any>) {
      const dataIsArray = data instanceof Array;

      this.subsequentLiability.forEach((item, i) => {
        this.subsequentLiability[i][columnName] = dataIsArray ? data[i] : data;
      });
    },

    setFireData(columnName: string, data: Array<any>) {
      const dataIsArray = data instanceof Array;

      this.fire.forEach((item, i) => {
        this.fire[i][columnName] = dataIsArray ? data[i] : data;
      });
    },
    setErstrisikosummenItems(columnName: string, data: Array<any>) {
      const dataIsArray = data instanceof Array;

      this.erstrisikosummenItems.forEach((item, i) => {
        this.erstrisikosummenItems[i][columnName] = dataIsArray ? data[i] : data;
      });
    },
    setPromotionalRewardsItems(columnName: string, data: Array<any>) {
      const dataIsArray = data instanceof Array;

      this.promotionalRewards.forEach((item, i) => {
        this.promotionalRewards[i][columnName] = dataIsArray ? data[i] : data;
      });
    },
    setAltbauData(fieldName: string, data: Array<any>) {
      const [oldBuilding5155] = this.oldBuilding5155;
      this.oldBuilding5155 = [{
        ...oldBuilding5155,
        [fieldName]: data[0],
      }];

      const [oldBuilding5180] = this.oldBuilding5180;
      this.oldBuilding5180 = [{
        ...oldBuilding5180,
        [fieldName]: data[1],
      }];

      const [oldBuilding5181] = this.oldBuilding5181;
      this.oldBuilding5181 = [{
        ...oldBuilding5181,
        [fieldName]: data[2],
      }];
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.ergebnisseTarifkalkulationItems = priceFormData.ergebnisseTarifkalkulationItems;
      this.vorschadenhistorieSelect = priceFormData.vorschadenhistorieSelect;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.ErgebnisRisikoadjustments = priceFormData.ErgebnisRisikoadjustments;
      this.SkillNachlasseUndZuschlage = priceFormData.SkillNachlasseUndZuschlage;
      this.SkillNachlasseUndZuschlageAltbau = priceFormData.SkillNachlasseUndZuschlageAltbau;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.vierAugenPrinzip.date = new Date(priceFormData.vierAugenPrinzip.date);
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.newConstructionPerformanceItems = priceFormData.newConstructionPerformanceItems;
      this.subsequentLiability = priceFormData.subsequentLiability;
      this.fire = priceFormData.fire;
      this.oldBuilding5155 = priceFormData.oldBuilding5155;
      this.oldBuilding5180 = priceFormData.oldBuilding5180;
      this.oldBuilding5181 = priceFormData.oldBuilding5181;
      this.oldBuilding6155 = priceFormData.oldBuilding6155;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    setPramieValues() {
      // column D
      this.ergebnisseTarifkalkulationItems.forEach((item, i) => {
        this.promotionalRewards[i].PremiumIncludingTariffDiscounts = item.Pramie;
      });
    },
    roundValues(value: number, digits = 2): number {
      return Number(value.toFixed(digits));
    },
    getPriceDataFromApi() {
      this.getPriceDataItemFromApi('tarif', 0);
      this.getPriceDataItemFromApi('variant1', 1);
      this.getPriceDataItemFromApi('variant2', 2);
      this.getPriceDataItemFromApi('variant3', 3);
    },
    getPriceDataItemFromApi(key: string, index: number) {
      if (!this.checkIfPriceDataHaveResponse(key)) {
        return;
      }
      this.oldBuilding5155[0].SB = transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5155?.items?.AltbauIndividualA?.SBinEUR ?? 0) * this.baukombiSurchargeСoefficient;
      this.oldBuilding5155[0].PremiumIncludingTariffDiscounts = transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht'].Altbau_Einsturz ?? 0) * this.baukombiSurchargeСoefficient;

      this.oldBuilding5180[0].SB = transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5180?.items?.AltbauIndividualA?.SBinEUR ?? 0) * this.baukombiSurchargeСoefficient;
      this.oldBuilding5180[0].PremiumIncludingTariffDiscounts = transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht']['Altbau_Sachschäden'] ?? 0) * this.baukombiSurchargeСoefficient;
      this.oldBuilding5181[0].SB = transformNumber(this.insuranceData?.spreadsheetData?.AltbauNachAZ5181?.items?.AltbauIndividualA?.SBinEUR ?? 0) * this.baukombiSurchargeСoefficient;
      this.oldBuilding5181[0].PremiumIncludingTariffDiscounts = transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht']['Altbau_Sachschäden'] ?? 0) * this.baukombiSurchargeСoefficient;

      this.ergebnisseTarifkalkulationItems[index].TarifPramie = (transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht']['Gesamtprämie']) + transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht'].Neubau_ERS)) * this.baukombiSurchargeСoefficient;
      calculatePramieergebnisseTarifkalkulationItem(this.ergebnisseTarifkalkulationItems[index]);

      // this.promotionalRewards[index].PremiumIncludingTariffDiscounts = this.ergebnisseTarifkalkulationItems[index].Pramie;
      this.newConstructionPerformanceItems[index].PremiumIncludingTariffDiscounts = (transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht'].Neubau_Grundbeitrag) * this.ergebnisseTarifkalkulationFactors[index]) * this.baukombiSurchargeСoefficient;
      this.erstrisikosummenItems[index].PremiumIncludingTariffDiscounts = ((transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht'].Neubau_ERS) + transformNumber(this.insuranceData?.spreadsheetData?.SachenImGefahrenbereich?.Zuschlag)) * this.ergebnisseTarifkalkulationFactors[index]) * this.baukombiSurchargeСoefficient;
      this.subsequentLiability[index].PremiumIncludingTariffDiscounts = (transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht'].Neubau_Nachhaftung) * this.ergebnisseTarifkalkulationFactors[index]) * this.baukombiSurchargeСoefficient;
      this.fire[index].PremiumIncludingTariffDiscounts = (transformNumber(this.priceData[key].priceResponse.Result['Prämienübersicht'].Beitrag_Feuer_nach_ABN) * this.ergebnisseTarifkalkulationFactors[index]) * this.baukombiSurchargeСoefficient;

      this.promotionalRewards[index].PremiumIncludingTariffDiscounts = this.summNeubau(index);

      this.calculatePramieTables();
      this.calculatePramieTablesAltbau();
    },
    calculatePramieTables() {
      const GesamtergebnisGemabRisikofaktorenbewertung = Number(this.avgRisk);
      this.newConstructionPerformanceItems.forEach((item, i) => {
        calculatePramieTableItem(item, GesamtergebnisGemabRisikofaktorenbewertung, this.discountsBonuses[i], this.zusatzlicherVorschadenfaktorСoefficient, this.zusammenfassung.versicherungssummeAktuell);
      });
      this.erstrisikosummenItems.forEach((item, i) => {
        calculatePramieTableItem(item, GesamtergebnisGemabRisikofaktorenbewertung, this.discountsBonuses[i], this.zusatzlicherVorschadenfaktorСoefficient, this.zusammenfassung.versicherungssummeAktuell);
      });
      this.subsequentLiability.forEach((item, i) => {
        calculatePramieTableItem(item, GesamtergebnisGemabRisikofaktorenbewertung, this.discountsBonuses[i], this.zusatzlicherVorschadenfaktorСoefficient, this.zusammenfassung.versicherungssummeAktuell);
      });
      this.promotionalRewards.forEach((item, i) => {
        calculatePramieTableItem(item, GesamtergebnisGemabRisikofaktorenbewertung, this.discountsBonuses[i], this.zusatzlicherVorschadenfaktorСoefficient, this.zusammenfassung.versicherungssummeAktuell);
      });
      this.fire.forEach((item, i) => {
        calculatePramieTableItem(item, GesamtergebnisGemabRisikofaktorenbewertung, this.discountsBonuses[i], this.zusatzlicherVorschadenfaktorСoefficient, this.zusammenfassung.versicherungssummeAktuell);
      });
    },
    calculatePramieTablesAltbau() {
      const { risikofactor } = this;

      this.oldBuilding5155.forEach((item, i) => {
        calculatePramieTableItem(item, risikofactor, this.discountsBonusesAltbau[i], this.zusatzlicherVorschadenfaktorСoefficient, this.oldBuilding5155NetOfferPremium);
      });
      this.oldBuilding5180.forEach((item, i) => {
        calculatePramieTableItem(item, risikofactor, this.discountsBonusesAltbau[i], this.zusatzlicherVorschadenfaktorСoefficient, this.oldBuilding5180NetOfferPremium);
      });
      this.oldBuilding5181.forEach((item, i) => {
        calculatePramieTableItem(item, risikofactor, this.discountsBonusesAltbau[i], this.zusatzlicherVorschadenfaktorСoefficient, this.oldBuilding5181NetOfferPremium);
      });
      this.oldBuilding6155.forEach((item, i) => {
        calculatePramieTableItem(item, risikofactor, this.discountsBonusesAltbau[i], this.zusatzlicherVorschadenfaktorСoefficient, this.zusammenfassung.versicherungssumme371);
      });
    },
    summNeubau(index: string): number {
      let result = 0;
      result += this.newConstructionPerformanceItems[index].PremiumIncludingTariffDiscounts;
      result += this.erstrisikosummenItems[index].PremiumIncludingTariffDiscounts;
      result += this.subsequentLiability[index].PremiumIncludingTariffDiscounts;
      result += this.fire[index].PremiumIncludingTariffDiscounts;
      return Number(result.toFixed(2));
    },
    checkIfPriceDataHaveResponse(key: string): boolean {
      if (typeof this.priceData[key].priceResponse !== 'object' || Object.keys(this.priceData[key].priceResponse).length === 0) {
        return false;
      }
      return true;
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.priceFormData) {
        recursiveFillData(this.zusammenfassung, this.insuranceFormsApiData.priceFormData.zusammenfassung);
      }
    },
    onNextClick(redirectToNextPage = true) {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        ergebnisseTarifkalkulationItems: this.ergebnisseTarifkalkulationItems,
        vorschadenhistorieSelect: this.vorschadenhistorieSelect,
        vorschadenhistorie: this.vorschadenhistorie,
        ErgebnisRisikoadjustments: this.ErgebnisRisikoadjustments,
        SkillNachlasseUndZuschlage: this.SkillNachlasseUndZuschlage,
        SkillNachlasseUndZuschlageAltbau: this.SkillNachlasseUndZuschlageAltbau,
        vierAugenPrinzip: this.vierAugenPrinzip,
        promotionalRewards: this.promotionalRewards,
        erstrisikosummenItems: this.erstrisikosummenItems,
        newConstructionPerformanceItems: this.newConstructionPerformanceItems,
        subsequentLiability: this.subsequentLiability,
        fire: this.fire,
        oldBuilding5155: this.oldBuilding5155,
        oldBuilding5180: this.oldBuilding5180,
        oldBuilding5181: this.oldBuilding5181,
        oldBuilding6155: this.oldBuilding6155,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };
      this.$emit('price-formData-changed', data);
      // if (redirectToNextPage) {
      //   // this.sendShortXML(this.id);
      //   const routeName = getNextRouteNameByKeyToCustomerDataHelper(this.insuranceKey);
      //   // console.log(routeName);
      //   this.$router.push({
      //     name: routeName,
      //     params: {
      //       id: this.$route.params.id,
      //     },
      //   }).catch((err: any) => {
      //     throw new Error(`Problem handling something: ${err}.`);
      //   });
      // }
      // console.log('test');
    },
    beforeDestroy() {
      if (this.isPCUser) return;
      this.onNextClick(false);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.fillApiValuesData();
    if (this.insuranceData.priceFormData) {
      this.setValues(this.insuranceData.priceFormData);
    }
    this.fillOldBuilding5155();
    this.fillOldBuilding5180();
    this.fillOldBuilding5181();

    if (this.insuranceData.spreadsheetData) {
      const coInsuranceOfFire = this.insuranceData.spreadsheetData.MitversicherungVonFeuer.MitversicherungVonFeuer === 'Ja' ?
        this.insuranceData.spreadsheetData.MitversicherungVonFeuer.Baunebenkosten : 0;

      this.zusammenfassung.versicherungssummeAktuell =
        this.insuranceData.spreadsheetData.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe1.Versicherungssumme +
        this.insuranceData.spreadsheetData.VersicherungssummeNeubauleistung.NeubauIndividualRisikogruppe2.Versicherungssumme +
        coInsuranceOfFire;

      const az5155Value = this.insuranceData.spreadsheetData.showAz5155 ?
        this.insuranceData.spreadsheetData.AltbauNachAZ5155.items.AltbauIndividualA.VSinEUR +
        this.insuranceData.spreadsheetData.AltbauNachAZ5155.items.AltbauIndividualB.VSinEUR +
        this.insuranceData.spreadsheetData.AltbauNachAZ5155.items.AltbauIndividualC.VSinEUR :
        0;

      const az5180Value = this.insuranceData.spreadsheetData.showAz5180 ?
        this.insuranceData.spreadsheetData.AltbauNachAZ5180.items.AltbauIndividualA.VSinEUR +
        this.insuranceData.spreadsheetData.AltbauNachAZ5180.items.AltbauIndividualB.VSinEUR +
        this.insuranceData.spreadsheetData.AltbauNachAZ5180.items.AltbauIndividualC.VSinEUR :
        0;

      const az5181Value = this.insuranceData.spreadsheetData.showAz5181 ?
        this.insuranceData.spreadsheetData.AltbauNachAZ5181.items.AltbauIndividualA.VSinEUR +
        this.insuranceData.spreadsheetData.AltbauNachAZ5181.items.AltbauIndividualB.VSinEUR +
        this.insuranceData.spreadsheetData.AltbauNachAZ5181.items.AltbauIndividualC.VSinEUR :
        0;

      this.zusammenfassung.versicherungssumme371 =
        this.insuranceData.spreadsheetData.Altbau.RisikoItems.Versicherungssumme.risiko1 +
        this.insuranceData.spreadsheetData.Altbau.RisikoItems.Versicherungssumme.risiko2 +
        this.insuranceData.spreadsheetData.Altbau.RisikoItems.Versicherungssumme.risiko3 +
        az5155Value +
        az5180Value +
        az5181Value;
    }
    (this as any).startWatcherActivated();
  },
});









































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import { PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import { calculateClaimsRequirementPremium,
  getProductPageBeforePriceTabHelper,
  onCurrencyFocus } from '@/helper/functions';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { saveZusammenfassungPDF } from '@/helper/handle-pdf';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'photovoltaics-price-card',
  mixins: [dataChangeMixin],
  components: {
    DatePicker,
    FormSwitch,
    xmlStatusIcon,
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'processSavingData',
    'productId',
    'priceData',
    'AMSAPISendStatus',
  ],
  data(): any {
    return {
      name: 'photovoltaics-price-card',
      key: PHOTOVOLTAIK_INSURANCE,
      pdfStyles: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: 0,
        versicherungssumme371: 0,
        produkt: '',
        nettoisiert: '',
        steuerland: '',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      kalkulationsergebnis: {
        hazardGroups: {
          feuer: '',
          BU: '',
          maschinenBruch: '',
          bauleistungMontage: '',
        },
      },
      vorschadenhistorie: {
        select: false,
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: 0,
        Betrachtungszeitraum: '',
        Zielschadenquote: '',
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: 0,
        Begrundung: '',
      },
      ergebnisseTarifkalkulationItems: {
        tarif: {
          name: 'SB-Tarif SB',
          selbstbehalt: '5.000 EUR / 7 tage',
          tarifPramie: 0,
          laufzeit: 1,
          SVR: 0,
          betrachtungszeitraum: '',
          SQ: '',
          nettoisierung: 0,
          kurzfristige: 0,
          pramieInklTariflicherNachlasse: 0,
        },
        SBVariante1: {
          name: 'SB-Variante 1',
          selbstbehalt: '',
          tarifPramie: 0,
          laufzeit: 1,
          SVR: 0,
          betrachtungszeitraum: '',
          SQ: '',
          nettoisierung: 0,
          kurzfristige: 0,
          pramieInklTariflicherNachlasse: 0,
        },
        SBVariante2: {
          name: 'SB-Variante 2',
          selbstbehalt: '',
          tarifPramie: 0,
          laufzeit: 1,
          SVR: 0,
          betrachtungszeitraum: '',
          SQ: '',
          nettoisierung: 0,
          kurzfristige: 0,
          pramieInklTariflicherNachlasse: 0,
        },
        SBVariante3: {
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
          tarifPramie: 0,
          laufzeit: 1,
          SVR: 0,
          betrachtungszeitraum: '',
          SQ: '',
          nettoisierung: 0,
          kurzfristige: 0,
          pramieInklTariflicherNachlasse: 0,
        },
      },
      ergebnisseTarifkalkulationCssClasses: {
        tarif: 'bg-gray',
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      skillVollmachten: {
        TariffVariant: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant1: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant2: {
          estates: 0,
          surcharges: 0,
        },
        SBvariant3: {
          estates: 0,
          surcharges: 0,
        },
        reason: '',
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
        notes: '',
      },
      promotionalRewards: [
        {
          pos: 'Tarif SB',
          SB: 0,
          PremiumIncludingTariffDiscounts: 0,
          PremiumIncludingRiskAdjustmentFactor: 0,
          PremiumIncludingMarketFactor: 0,
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: 0,
          NetOfferPremiumRate: 0,
          GrossOfferPremium: 0,
          PramiensatzNettoMietumsatz: 0,
        },
        {
          pos: 'SB-Variante 1',
          SB: 0,
          PremiumIncludingTariffDiscounts: 0,
          PremiumIncludingRiskAdjustmentFactor: 0,
          PremiumIncludingMarketFactor: 0,
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: 0,
          NetOfferPremiumRate: 0,
          GrossOfferPremium: 0,
          PramiensatzNettoMietumsatz: 0,
        },
        {
          pos: 'SB-Variante 2',
          SB: 0,
          PremiumIncludingTariffDiscounts: 0,
          PremiumIncludingRiskAdjustmentFactor: 0,
          PremiumIncludingMarketFactor: 0,
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: 0,
          NetOfferPremiumRate: 0,
          GrossOfferPremium: 0,
          PramiensatzNettoMietumsatz: 0,
        },
        {
          pos: 'SB-Variante 3',
          SB: 0,
          PremiumIncludingTariffDiscounts: 0,
          PremiumIncludingRiskAdjustmentFactor: 0,
          PremiumIncludingMarketFactor: 0,
          BonusIncludingSkillDiscounts: '',
          NetOfferPremium: 0,
          NetOfferPremiumRate: 0,
          GrossOfferPremium: 0,
          PramiensatzNettoMietumsatz: 0,
        },
      ],
      deductibleVariantsCssClasses: {
        0: '',
        1: 'table-variant-1',
        2: 'table-variant-2',
        3: 'table-variant-3',
      },
      hinweiseZumBeitrag: '',
    };
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData.priceFormData) {
      this.setValues(this.insuranceData.priceFormData);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
  },
  methods: {
    getXmlBtnIsDisable(index: string) {
      if (this.AMSAPISendStatus !== null) {
        const status = this.AMSAPISendStatus[index];
        if (status !== null) {
          return (!status.dataUpdated && status.statusSuccess);
        }
      }
      return false;
    },
    onCurrencyFocus,
    onSubmit() {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        kalkulationsergebnis: this.kalkulationsergebnis,
        skillVollmachten: this.skillVollmachten,
        ergebnisseTarifkalkulationItems: this.ergebnisseTarifkalkulationItems,
        vorschadenhistorie: this.vorschadenhistorie,
        vierAugenPrinzip: this.vierAugenPrinzip,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };

      this.$emit('price-formData-changed', data);
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.kalkulationsergebnis = priceFormData.kalkulationsergebnis;
      this.skillVollmachten = priceFormData.skillVollmachten;
      this.ergebnisseTarifkalkulationItems = priceFormData.ergebnisseTarifkalkulationItems;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.vierAugenPrinzip = {
        ...this.vierAugenPrinzip,
        ...priceFormData.vierAugenPrinzip,
      };
      this.vierAugenPrinzip.date = new Date(priceFormData.vierAugenPrinzip.date);
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    goToBackUrl() {
      const routeName = getProductPageBeforePriceTabHelper(this.insuranceKey);

      this.$emit('go-back-tab', routeName, 'insuranceForms', 5);
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Photovoltaics-Preis-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 6,
        filename,
        html2canvas: {
          width: 1850,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    queueDownloadBigXML(i) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onSubmit);
      this.$emit('add-to-queue', 'downloadBigXML', this.getVariantFromIndex(i));
      this.onSubmit();
    },
    queueSendBigXML(i) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onSubmit);
      this.$emit('add-to-queue', 'sendBigXML', this.getVariantFromIndex(i));
      this.onSubmit();
    },
    getVariantFromIndex(index) {
      switch (index) {
        case 0:
          return 'tarif';
        case 1:
          return 'variant1';
        case 2:
          return 'variant2';
        case 3:
          return 'variant3';
        default:
          return 'tarif';
      }
    },
  },
});

/* eslint-disable */
// @ts-ignore

import {
  ASSEMBLY_INSURANCE_KEY,
  CASE_STATUS,
  PHOTOVOLTAIK_INSURANCE,
  WINDENERGIEANLAGEN_INSURANCE_KEY,
} from '@/constant/constants';
import conditionsFiles from '@/config/conditions-files';
import InsuranceTab from "@/model/tabs";
import tabs from "@/components/tabs.vue";
import { DuplicateElement } from '@/model/DuplicateElement';
import moment from 'moment';

function getProductNameHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return 'individual-insurance';
    default:
      return '';
  }
}
function getProductPageBeforePriceTabHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return 'individual-insurance-risk';
    case 'CONSTRUCTION_WORK_INSURANCE':
      return 'bauleistung-insurance-risk';
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return 'bauleistung-insurance-risk';
    case WINDENERGIEANLAGEN_INSURANCE_KEY:
      return 'windenergieanlagen-insurance-tarif';
    case ASSEMBLY_INSURANCE_KEY:
      return 'assembly-insurance-tarif';
    case PHOTOVOLTAIK_INSURANCE:
      return 'photovoltaics-insurance-risk';
    default:
      return '';
  }
}
function getProductNameForPricePageHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return 'individual-insurance';
    default:
      return '';
  }
}
function getNextRouteNameByKeyToCustomerDataHelper(key: string): string {
  let path = '';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-customer-data';
      break;
    default:
      break;
  }
  return path;
}

function getNextRouteNameByKeyToCustomerGeneralHelper(key: string): string {
  let path = 'individual-insurance-customer-general';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-customer-general';
      break;
    default:
      break;
  }
  return path;
}
function getNextRouteNameByKeyToPricePageHelper(key: any) {
  let path = '';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-price-card';
      break;
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      path = 'individual-insurance-price-card';
      break;
    case 'CONSTRUCTION_WORK_INSURANCE':
      path = 'bauleistung-insurance-price-card';
      break;
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      path = 'bauleistung-insurance-price-card';
      break;
    case 'BIOGAS_INSURANCE':
      path = 'biogas-insurance-price-card';
      break;
    default:
      break;
  }
  return path;
}
function getNextRouteNameByKeyToProposalPolicyHelper(key: any) {
  let path = '';
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      path = 'individual-insurance-proposal-policy';
      break;
    default:
      break;
  }
  return path;
}
function getLinkHelper(key: string): string {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return '/individual-insurance';
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      return '/individual-insurance';
    case 'CONSTRUCTION_WORK_INSURANCE':
      return '/bauleistung-insurance';
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return '/bauleistung-insurance';
    case 'ELEKTRONIK_INSURANCE':
      return '/elektronik-insurance';
    case 'BIOGAS_INSURANCE':
      return '/biogas-insurance';
    case 'WINDENERGIEANLAGEN_INSURANCE':
      return '/windenergieanlagen-insurance';
    case 'ASSEMBLY_INSURANCE':
      return '/assembly-insurance';
    default:
      return '/products';
  }
}
function getTabInsuranceForms(key: string, params: any = null): object {
  let insuranceForms = {
    1: {
      name: 'Kalkulationsblatt',
      path: '/',
    },
    2: {
      name: 'Risikofaktoren',
      path: '/risk',
    },
  };
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'individual-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'individual-insurance-calc',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'individual-insurance-risk',
        },
      };
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'individual-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'machines-power-plants-calc',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'individual-insurance-risk',
        },
      };
    case 'CONSTRUCTION_WORK_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'bauleistung-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'bauleistung-calc-2a-ABN',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'bauleistung-insurance-risk',
        },
      };
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'bauleistung-insurance',
        },
        2: {
          name: 'Kalkulationsblatt',
          path: 'bauleistung-calc-2b-ABU',
        },
        3: {
          name: 'Risikofaktoren',
          path: 'bauleistung-insurance-risk',
        },
      };
    case 'ELEKTRONIK_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'elektronik-insurance',
        },
        2: {
          name: 'ELEK - Einzel',
          path: 'elektronik-insurance-ELEKEinzel',
        },
        3: {
          name: 'ELEK - Ergänzungstarif',
          path: 'elektronik-insurance-ELEKErganzungstarif',
        },
        4: {
          name: 'ELEK - Pauschal',
          path: 'elektronik-insurance-ELEKPauschal',
        },
        5: {
          name: 'Praxispolice',
          path: 'elektronik-insurance-Praxispolice',
        },
        6: {
          name: 'Risikofaktoren',
          path: 'elektronik-insurance-risk',
        },
      };
    case 'WINDENERGIEANLAGEN_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'windenergieanlagen-insurance',
        },
        2: {
          name: 'EEWEA Tarifkalkulation',
          path: 'windenergieanlagen-insurance-tarif',
        },
      };
    case 'BIOGAS_INSURANCE': {
      const biogasMenuData = {
        1: {
          name: 'Grunddaten',
          path: 'biogas-insurance',
        },
        2: {
          name: 'EEBIO Tarifkalkulation',
          path: 'biogas-insurance-tarif',
        }
      };

      if (params?.selectedBu) {
        biogasMenuData[3] = {
          name: 'Risikoerfassung BU',
          path: 'biogas-insurance-risk',
        };
      }

      return biogasMenuData;
    }
    case 'ASSEMBLY_INSURANCE':
      return {
        1: {
          name: 'Grunddaten',
          path: 'assembly-insurance',
        },
        2: {
          name: 'MON Tarifkalkulation',
          path: 'assembly-insurance-tarif',
        },
      };
    case PHOTOVOLTAIK_INSURANCE:
      return {
        1: {
          name: 'Grunddaten',
          path: 'photovoltaics-basic',
        },
        2: {
          name: 'EEPV nach ABE',
          path: 'photovoltaics-insurance-EEPV',
        },
        3: {
          name: 'BU ABE',
          path: 'photovoltaics-insurance-BU-ABE',
        },
        4: {
          name: 'EEPV nach ABPV',
          path: 'photovoltaics-insurance-EEPV-ABPV',
        },
        5: {
          name: 'Risikofaktoren',
          path: 'photovoltaics-insurance-risk',
        }
      };
    default:
      return insuranceForms;
  }
}

function getTabPriceForms(key: string, params: any = null): object {
  let PriceForms = {
      1: {
        name: 'Preis',
        path: 'price-card',
      },
    };
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'individual-insurance-price-card',
        },
      };
    case 'MACHINES_POWER_PLANTS_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'individual-insurance-price-card',
        },
      };
    case 'CONSTRUCTION_WORK_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'bauleistung-insurance-price-card',
        },
      };
    case 'CONSTRUCTION_WORK_ABU_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'bauleistung-insurance-price-card',
        },
      };
    case 'ELEKTRONIK_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'elektronik-insurance-price-card',
        },
      };
    case 'WINDENERGIEANLAGEN_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'windenergieanlagen-insurance-price-card',
        },
      };
    case 'BIOGAS_INSURANCE': {
      const biogasPriceMenu = {
        1: {
          name: 'Preis',
          path: 'biogas-insurance-price-card',
        },
      };

      if (params?.selectedBu) {
        biogasPriceMenu[2] = {
          name: 'Ergebnisseite BU',
          path: 'biogas-insurance-results-page',
        };
      }

      return biogasPriceMenu;
    }
    case 'ASSEMBLY_INSURANCE':
      return {
        1: {
          name: 'Preis',
          path: 'assembly-insurance-price-card',
        },
      };
    case PHOTOVOLTAIK_INSURANCE:
      return {
         1: {
           name: 'Ergebnisseite',
           path: 'photovoltaics-price-card',
         },
      };
    default:
      return PriceForms;
  }
}

//unnecessary functions that may be useful later - start
// function getTabcustomerForms(key: string): object {
//   let customerForms = {
//     1: {
//       name: 'Kundendaten',
//       path: 'individual-insurance-customer-data',
//     },
//   };
//   return customerForms;
// }
// function getTabPolice(key: string): object {
//   let policeTab = {
//     1: {
//       name: 'Vorschlag / Police',
//       path: 'individual-insurance-proposal-policy',
//     },
//   };
//   return policeTab;
// }
// function getTabFiles(key: string): object {
//   let filesTab = {
//     1: {
//       name: 'Bedingungen / Dateien',
//       path: '/',
//     },
//   };
//   return filesTab;
// }
//unnecessary functions that may be useful later - end


type tabs = {
  insuranceForms: any;
  priceForms: any;
  // customerForms: any;
  // police: any;
  // files: any;
};

type dateDifference = {
  value: number,
  period: 'Tage' | 'Monate' | 'Wochen'
};

function getTabsHelper(key: string, params: any = null): tabs {
  return {
    insuranceForms: getTabInsuranceForms(key, params),
    priceForms: getTabPriceForms(key, params),
    // customerForms: getTabcustomerForms(key),
    // police: getTabPolice(key),
    // files: getTabFiles(key),
  }
}
// function getActiveTabsHelper(key: string, activeTabsIndex: any): object {
//   let tabs = getTabInsuranceForms(key);
//   result = {};
//   activeTabsIndex.forEach((element: any, index) => {
//     result['index'] = tabs['index']
//   });
//   return result;
// }
function getTabInfoHelper(tab: InsuranceTab, tabs: object): InsuranceTab {
  const result: InsuranceTab = {
    groupId: tab.groupId,
    tabId: tab.tabId,
    name: tabs[tab.groupId][tab.tabId].name,
    path: tabs[tab.groupId][tab.tabId].path,
  };
  return result;
}

function getTabGroupIdNumberHelper(groupId) {
  switch (groupId) {
    case 'insuranceForms':
      return 1;
    case 'priceForms':
      return 2;
    // case 'customerForms':
    //   return 3;
    // case 'police':
    //   return 4;
    // case 'files':
    //   return 5;
    default:
      return 0;
  }
}

function getConditionsFilesHelper(key: string): object {
  switch (key) {
    case 'INDIVIDUAL_INSURANCE':
      return conditionsFiles.INDIVIDUAL_INSURANCE;
    default:
      return {};
  }
}

function recursiveFillData(target, source) {
  if (typeof target !== 'object' || target === null || typeof source !== 'object' || source === null) {
    // If either target or source is not an object or is null, stop the recursion
    return;
  }

  for (const key in target) {
    if (Object.prototype.hasOwnProperty.call(target, key)) {
      if ((typeof target[key] === 'string' || typeof target[key] === 'number' || typeof target[key] === 'boolean' ||  target[key] === null) && source.hasOwnProperty(key)) {
        if (typeof target[key] === 'number' || target[key] === null) {
          if (source[key] != null && source[key] != 0) {
            target[key] = Number(source[key]);
          }
        } else {
          if (source[key] != null) {
            target[key] = source[key];
          }
        }
      } else if (typeof target[key] === 'object' || Array.isArray(target[key])) {
        if (source.hasOwnProperty(key)) {
          recursiveFillData(target[key], source[key]);
        }
      }
    }
  }
}
//
// function recursiveFillData(target, source) {
//   for (const key in target) {
//     if ((typeof target[key] === 'string' || (target[key] === null)) && source.hasOwnProperty(key)) {
//       if(typeof target[key] === 'number') {
//         target[key] = Number(source[key]);
//       } else {
//         target[key] = source[key];
//       }
//     } else if (typeof target[key] === 'object' || Array.isArray(target[key])) {
//       if (source.hasOwnProperty(key)) {
//         recursiveFillData(target[key], source[key]);
//       }
//     }
//   }
// }

function unselectFirstElement(list: object[], fieldName = 'isSelected'): void {
  const unselected = list.filter(item => !item[fieldName]);

  if (list.length > 0 && unselected.length === 0) {
    list[0][fieldName] = false;
  }
}

const duplicateDataItems = (insertItem: DuplicateElement, dataItems: DuplicateElement[], fillData?: boolean) => {
  const selectedElements = dataItems.filter((itm) => itm.isSelected);

  if (fillData && selectedElements.length > 0) {
    dataItems.push(...copyObject(selectedElements));
  }

  if (!fillData) {
    dataItems.push(...[copyObject(insertItem)]);
  }
};

const createXMLKeys = () => ({
  AMSPK: generateUniqueAmsId(),
  AMSIDNR: generateUniqueAmsId(),
  fillFromAMS: false,
});
const duplicateIndividualPositionsdatenItems = (insertItem: DuplicateElement, dataItems: DuplicateElement[], fillData?: boolean) => {
  const selectedElements = dataItems.filter((itm) => itm.isSelected);

  const copySelectedElements = copyObject(selectedElements);

  copySelectedElements.forEach((element: any, index) => {
    element.keysForXML = createXMLKeys();
  });

  if (fillData && selectedElements.length > 0) {
    dataItems.push(...copySelectedElements);
  }

  if (!fillData) {
    insertItem.keysForXML = createXMLKeys();
    dataItems.push(...[copyObject(insertItem)]);
  }
};

const duplicateDataItemsWithoutSelect = (insertItem: any, dataItems: any[]) => {
  dataItems.push(JSON.parse(JSON.stringify(insertItem)));
};

const differenceBetweenDates = (beginDate: string, endDate: string): dateDifference => {
  const dateBegin = moment(beginDate, 'YYYY-MM-DD');
  const dateEnd = moment(endDate, 'YYYY-MM-DD');

  // Get the difference in days
  const diffInDays = dateEnd.diff(dateBegin, 'days');

  // If the difference is less than 7 days, return the number of days
  if (diffInDays < 7) {
    return {
      value: diffInDays,
      period: 'Tage'
    };
  }

  // Get the difference in weeks
  const diffInWeeks = dateEnd.diff(dateBegin, 'weeks');

  // If the difference is less than 4 weeks, return the number of weeks
  if (diffInWeeks < 4) {
    return {
      value: diffInWeeks,
      period: 'Wochen'
    };
  }

  // Get the difference in months
  let diffInMonths = dateEnd.diff(dateBegin, 'months');

  // Create a copy of dateBegin to calculate the remainder days
  const dateBeginCopy = dateBegin.clone();

  // Get the remaining days after full months
  const remainderDays = dateEnd.diff(dateBeginCopy.add(diffInMonths, 'months'), 'days');

  // If remainder days are less than 7 to the next full month, round up
  const daysInMonth = dateBegin.daysInMonth();
  if (remainderDays > 21) {
    diffInMonths++;  // Round up to the next full month
  } else if (remainderDays >= Math.round(daysInMonth / 2)) {
    diffInMonths += 0.5;  // Add 0.5 if the remainder days are more than half a month
  }

  // Return the number of months, including fractional months
  return {
    value: diffInMonths,
    period: 'Monate'
  };
};

const differenceInFullMonths = (beginDate: string, endDate: string): dateDifference => {
  const dateBegin = moment(beginDate, 'YYYY-MM-DD');
  const dateEnd = moment(endDate, 'YYYY-MM-DD');

  // Get the difference in months
  let diffInMonths = dateEnd.diff(dateBegin, 'months');

  // Create a copy of dateBegin to calculate the remainder days
  const dateBeginCopy = dateBegin.clone();

  // Get the remaining days after full months
  const remainderDays = dateEnd.diff(dateBeginCopy.add(diffInMonths, 'months'), 'days');

  // Always round up if there are any remainder days
  if (remainderDays > 0) {
    diffInMonths++;
  }

  return {
    value: diffInMonths,
    period: 'Monate'
  };
};


const calculateClaimsRequirementPremium = (
  totalDamage: number,
  totalReserves: number,
  damageHistoryObservationPeriod: number,
  targetLossPercents: number
): number => {
  const targetLossRatio = targetLossPercents / 100;

  if (damageHistoryObservationPeriod !== 0 && targetLossRatio !== 0) {
    return ((totalDamage + totalReserves) / damageHistoryObservationPeriod) / targetLossRatio;
  }

  return 0;
};

const copyObject = (object: any): any => {
  return JSON.parse(JSON.stringify(object));
};

function transformNumber(value, precision = 2) {
  // Ensure value is a number
  const numericValue = Number(value);

  // Check if the numericValue is a valid number
  if (!isNaN(numericValue)) {
    // Use toFixed to round to the specified precision and then convert back to a number
    return Number(numericValue.toFixed(precision));
  } else {
    // Handle the case where the input is not a valid number
    console.error(`Invalid number: ${value}`);
    return NaN;
  }
}

function transformToCurrencyStr(value: number): string {
  return value.toFixed(2)
    .replace('.', ',')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' EUR'
}

function groupByKey(items: any[], key: string, additionalNoneSelectedValues: any[] = []): any {
  return items.reduce((accumulator, currentValue) => {
    const accumulatorElement = accumulator;

    const noneSelectedValues: any[] = [null, 'null']

    const keyName = noneSelectedValues
      .concat(additionalNoneSelectedValues)
      .includes(currentValue[key]) ?
        'Nicht ausgewählt' :
        currentValue[key];

    if (!accumulatorElement[keyName]) {
      accumulatorElement[keyName] = +currentValue.insurance_count;
    } else {
      accumulatorElement[keyName] += +currentValue.insurance_count;
    }

    return accumulatorElement;
  }, {
  });
}

function onCurrencyFocus(param) {
  const idElement = param?.target?.attributes?.id?.value;

  if (idElement) {
    const target = document.getElementById(idElement) as any;

    const comaPosition = target?.value ? String(target.value).indexOf(',') : -1;

    if (target &&
      target.selectionStart === 0 &&
      target.selectionStart !== null &&
      comaPosition !== -1
    ) {
      target.focus();
      target.setSelectionRange(comaPosition, comaPosition);
    }
  }
}

function downloadFile(data: any, fileName: string) {
  const fileURL = window.URL.createObjectURL(new Blob([data]));

  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
}

function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (obj1 == null || obj2 == null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

function mapBoolValues(value: any): any {
  if (value === 'true') {
    return 'Ja';
  }

  if (value === 'false') {
    return 'Nein';
  }

  return value;
}

function getStatisticData(items: any, groupKey: string) {
  const values: any[] = [];
  const labels: any[] = [];
  const excelData: any[] = [];
  let statisticTotal = 0;

  const transformedNameItems = groupByKey(items, groupKey);

  Object.keys(transformedNameItems).forEach((key) => {
    const keyName = mapBoolValues(key)

    labels.push(`${keyName}: ${transformedNameItems[key]}`);
    values.push(transformedNameItems[key]);

    statisticTotal += transformedNameItems[key];

    excelData.push({
      name: key,
      value: transformedNameItems[key],
    });
  });

  return {
    values,
    labels,
    statisticTotal,
    excelData,
  };
}
function handlePremiumCategory(
  showCategory: boolean,
  items: any,
  beitragArray: any[],
  targetCategory: any,
  beitragssatzField: string,
  vsuField: string,
  betragField: string,
  arrayName: string,
  altbauNachCoefficients: any,
  specialConstructionMeasuresOldBuilding: number,
) {
  if (!showCategory) return;

  Object.keys(items).forEach((key) => {
    const item = items[key];
    if (item.VSinEUR === null || item.VSinEUR === 0) return;

    if (beitragArray.length > 0) {
      const obj = beitragArray.shift();
      if (obj) {
        targetCategory[key] = {
          VSU: Number(obj[vsuField]),
          SB: Number(item.SBinEUR),
          Beitragssatz: Number(obj[beitragssatzField]),
          Betrag: Number(obj[betragField]) + ((altbauNachCoefficients[key] ?? 0) * specialConstructionMeasuresOldBuilding),
        };
      }
    } else {
      console.warn(`Not enough elements in ${arrayName} to process key: ${key}`);
    }
  });
}

function distributeCoefficients(checkboxes) {
  if (!checkboxes || typeof checkboxes !== "object") {
    return {};
  }

  const selectedKeys = Object.keys(checkboxes).filter(key => checkboxes[key]);
  const selectedCount = selectedKeys.length;

  if (selectedCount === 0) {
    return Object.fromEntries(Object.keys(checkboxes).map(key => [key, 0]));
  }

  const coefficientPerSelected = 1 / selectedCount;

  return Object.fromEntries(
    Object.keys(checkboxes).map(key => [
      key,
      checkboxes[key] ? coefficientPerSelected : 0,
    ])
  );
}
function altbauNachIsDisable(items: any) {
  const result = {};
  Object.keys(items).forEach((key) => {
    const item = items[key];
    result[key] = (item.VSinEUR === null || item.VSinEUR === 0)
  });
  return result;
}
function disableAltbauNachIfValuesFalse(items: any, BesondereBaumabnahmenSelectItems: any) {

  if (!BesondereBaumabnahmenSelectItems || typeof BesondereBaumabnahmenSelectItems !== "object") {
    return;
  }

  Object.keys(items).forEach((key) => {
    const item = items[key];
    if (item.VSinEUR === null || item.VSinEUR === 0) {
      BesondereBaumabnahmenSelectItems[key] = false;
    }
  });
}


function getMachineCode(value: string | null = null): string {
  const data: Record<string, string> = {
    'Biogasanlage mit Gasottoverfahren': '1536',
    'Gasottoverfahren': '1536',
    'Biogasanlage mit Zündstrahlverfahren': '1535',
    'Zündstrahlverfahren': '1535',
    'Biogasanlage o. Verbrennungsmotor': '1539',
    'Fermenterbiologie Biogas Gasotto mit/ohne Motor': '1538',
    'Fermenterbiologie Biogas Zündstrahl': '1537'
  };

  return data[value || ''] || '';
}

function generateUniqueAmsId(): string {
  const uuid = `${generateRandomSegment()}${generateRandomSegment()}-${generateRandomSegment()}-${generateTimeSegment()}-${generateVariantSegment()}-${generateRandomSegment()}${generateRandomSegment()}${generateRandomSegment()}`;
  return uuid;

  function generateRandomSegment(): string {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  function generateTimeSegment(): string {
    return ((Math.floor(Math.random() * 0x1000) | 0x4000).toString(16)).substring(0, 4);
  }

  function generateVariantSegment(): string {
    return ((Math.floor(Math.random() * 0x4000) | 0x8000).toString(16)).substring(0, 4);
  }
}




export  {
  getProductNameHelper,
  getNextRouteNameByKeyToCustomerDataHelper,
  getNextRouteNameByKeyToCustomerGeneralHelper,
  getProductNameForPricePageHelper,
  getNextRouteNameByKeyToPricePageHelper,
  getLinkHelper,
  getTabsHelper,
  getNextRouteNameByKeyToProposalPolicyHelper,
  getConditionsFilesHelper,
  getProductPageBeforePriceTabHelper,
  getTabInfoHelper,
  getTabGroupIdNumberHelper,
  unselectFirstElement,
  duplicateDataItems,
  duplicateDataItemsWithoutSelect,
  recursiveFillData,
  differenceBetweenDates,
  calculateClaimsRequirementPremium,
  copyObject,
  transformNumber,
  tabs,
  transformToCurrencyStr,
  groupByKey,
  onCurrencyFocus,
  downloadFile,
  deepEqual,
  getStatisticData,
  differenceInFullMonths,
  handlePremiumCategory,
  getMachineCode,
  generateUniqueAmsId,
  duplicateIndividualPositionsdatenItems,
  createXMLKeys,
  distributeCoefficients,
  altbauNachIsDisable,
  disableAltbauNachIfValuesFalse
};

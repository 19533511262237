










































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import FormSwitch from '@/components/form/form-switch.vue';
import DatePicker from '@/components/form/date-picker.vue';
import { calculateClaimsRequirementPremium,
  copyObject,
  getProductPageBeforePriceTabHelper,
  onCurrencyFocus,
  recursiveFillData } from '@/helper/functions';
import { saveZusammenfassungPDF } from '@/helper/handle-pdf';
import { fillErgebnisseTarifkalkulationPramie } from '@/helper/priceCalculator/individual-calculator';
import xmlStatusIcon from '@/components/form/XML-status-icon.vue';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';
import popUpModal from '@/components/form/pop-up-modal.vue';
import { MACHINE_FAHRBAR, MACHINE_KRAFTWERKE, MACHINE_STATIONAR } from '@/constant/constants';
import Btns from '@/components/form/police-btns.vue';

const promotionalRewardsItem = {
  pos: 'Tarif SB',
  SB: 0,
  PremiumIncludingTariffDiscounts: 0,
  PremiumIncludingRiskAdjustmentFactor: 0,
  PremiumIncludingMarketFactor: 0,
  BonusIncludingSkillDiscounts: '',
  NetOfferPremium: 0,
  NetOfferPremiumRate: 0,
  GrossOfferPremium: 0,
  PramiensatzNettoMietumsatz: 0,
};

const premieItem = {
  tarifPramie: 0,
  laufzeit: 1,
  SVR: 0,
  betrachtungszeitraum: '',
  SQ: '',
  nettoisierung: 0,
  kurzfristige: 0,
  pramieInklTariflicherNachlasse: 0,
  showInPdf: true,
};

export default Vue.extend({
  name: 'price-card',
  mixins: [dataChangeMixin],
  components: {
    Btns,
    popUpModal,
    FormSwitch,
    DatePicker,
    xmlStatusIcon,
  },
  computed: {
    tarifSelbstbehalts(): any {
      const resultObject: any = {
      };

      Object.keys(this.pramienSelbstbehaltsvariantenItems).forEach((key) => {
        resultObject[key] = this.pramienSelbstbehaltsvariantenItems[key].selbstbehalt;

        if (key === 'tarif' || key === 'SBVariante3') {
          resultObject[key] = this.defaultSelbstbehalt[key];
        }
      });

      return resultObject;
    },
    isApiUser(): boolean {
      return this.$store.getters['authModule/isApiUser'];
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
    zusatzlicherVorschadenfaktorСoefficient(): number {
      return (100 - this.vorschadenhistorie.ZusatzlicherVorschadenfaktor) / 100;
    },
    hasPriceFormData(): boolean {
      return (this.insuranceData.priceFormData !== null && this.insuranceData.priceFormData !== undefined);
    },
    isMachinesPowerPlants(): boolean {
      return this.insuranceKey === 'MACHINES_POWER_PLANTS_INSURANCE';
    },
    vorschadenhistorieSummeSchaden(): number {
      return (+this.vorschadenhistorie.jahr1.schaden) +
        (+this.vorschadenhistorie.jahr2.schaden) +
        (+this.vorschadenhistorie.jahr3.schaden) +
        (+this.vorschadenhistorie.jahr4.schaden) +
        (+this.vorschadenhistorie.jahr5.schaden);
    },
    vorschadenhistorieSummeReserven(): number {
      return (+this.vorschadenhistorie.jahr1.reserven) +
        (+this.vorschadenhistorie.jahr2.reserven) +
        (+this.vorschadenhistorie.jahr3.reserven) +
        (+this.vorschadenhistorie.jahr4.reserven) +
        (+this.vorschadenhistorie.jahr5.reserven);
    },
    // Schadenbedarfsprämie
    claimsRequirementPremium(): number {
      return calculateClaimsRequirementPremium(
        this.vorschadenhistorieSummeSchaden,
        this.vorschadenhistorieSummeReserven,
        +this.vorschadenhistorie.Betrachtungszeitraum,
        +this.vorschadenhistorie.Zielschadenquote,
      );
    },
    risikofactor(): number {
      if (this.insuranceData.riskFactorData && this.insuranceData.riskFactorData.ergebnisDerBewertung) {
        return Number(this.insuranceData.riskFactorData.ergebnisDerBewertung.GesamtergebnisGemabRisikofaktorenbewertung);
      }
      return 1;
    },
  },
  props: [
    'id',
    'insuranceKey',
    'insuranceData',
    'productId',
    'processSavingData',
    'priceData',
    'AMSAPISendStatus',
    'insuranceFormsApiData',
    'dataUpdated',
  ],
  data(): any {
    return {
      backUrl: 'individual-insurance-risk',
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      zusammenfassung: {
        versicherungsnehmer: '',
        betriebsart: '',
        versicherungssummeAktuell: 0,
        versicherungssumme371: 0,
        produkt: '',
        nettoisiert: '',
        steuerland: 'Deutschland',
        vorversicherer: '',
        versicherungsscheinnummerVorvertrag: '',
        PML: 0,
      },
      vorschadenhistorieSelect: false,
      vorschadenhistorie: {
        jahr1: {
          schaden: 0,
          reserven: 0,
        },
        jahr2: {
          schaden: 0,
          reserven: 0,
        },
        jahr3: {
          schaden: 0,
          reserven: 0,
        },
        jahr4: {
          schaden: 0,
          reserven: 0,
        },
        jahr5: {
          schaden: 0,
          reserven: 0,
        },
        summe: {
          schaden: 0,
          reserven: 0,
        },
        SBVorvertrag: '',
        Betrachtungszeitraum: '',
        Zielschadenquote: '',
        MindestjahrespramieTarifVariante: '',
        MindestjahrespramieSBVariante1: '',
        MindestjahrespramieSBVariante2: '',
        MindestjahrespramieSBVariante3: '',
        ZusatzlicherVorschadenfaktor: '',
        Begrundung: '',
      },
      vierAugenPrinzip: {
        show: false,
        discussedWith: '',
        date: '',
        notes: '',
      },
      vertragAufMietumsatzbasis: {
        show: false,
        plannedRentalRevenue: 0,
      },
      datum: '',
      skillDiscountsItems: [],
      defaultSelbstbehalt: {
        tarif: 'Tarif SB-Variante',
        SBVariante3: 'Misch SB-Variante',
      },
      pramienSelbstbehaltsvariantenItems: {
        tarif: {
          name: 'SB-Tarif SB',
          selbstbehalt: 'Tarif SB-Variante',
          ...copyObject(premieItem),
        },
        SBVariante1: {
          name: 'SB-Variante 1',
          selbstbehalt: '',
          ...copyObject(premieItem),
        },
        SBVariante2: {
          name: 'SB-Variante 2',
          selbstbehalt: '',
          ...copyObject(premieItem),
        },
        SBVariante3: {
          name: 'SB-Variante 3',
          selbstbehalt: 'Misch SB-Variante',
          ...copyObject(premieItem),
        },
      },
      ergebnisseTarifkalkulationCssClasses: {
        tarif: 'bg-gray',
        SBVariante1: 'table-variant-1',
        SBVariante2: 'table-variant-2',
        SBVariante3: 'table-variant-3',
      },
      skillDiscounts: {
        resultRiskFactors: 0,
        skillDiscountTariffVariant: {
          estates: '',
          surcharges: '',
        },
        skillDiscountSBvariant1: {
          estates: '',
          surcharges: '',
        },
        skillDiscountSBvariant2: {
          estates: '',
          surcharges: '',
        },
        skillDiscountSBvariant3: {
          estates: '',
          surcharges: '',
        },
        reason: '',
      },
      promotionalRewards: [
        {
          ...promotionalRewardsItem,
          pos: 'Tarif SB',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 1',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 2',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 3',
        },
      ],
      maschinenItems: [
        {
          ...promotionalRewardsItem,
          pos: 'Tarif SB',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 1',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 2',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 3',
        },
      ],
      erstrisikosummenItems: [
        {
          ...promotionalRewardsItem,
          pos: 'Tarif SB',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 1',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 2',
        },
        {
          ...promotionalRewardsItem,
          pos: 'SB-Variante 3',
        },
      ],
      deductibleVariantsCssClasses: {
        0: '',
        1: 'table-variant-1',
        2: 'table-variant-2',
        3: 'table-variant-3',
      },
      pdfStyles: false,
      hinweiseZumBeitrag: '',
    };
  },
  watch: {
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        this.fillApiValuesData();
      }
    },
    processSavingData(value) {
      if (this.insuranceData.riskFactorData) {
        this.skillDiscounts.resultRiskFactors = this.insuranceData.riskFactorData.ergebnisDerBewertung.GesamtergebnisGemabRisikofaktorenbewertung;
      }
    },
    'insuranceData.spreadsheetData': function () {
      if (this.insuranceData && this.insuranceData.spreadsheetData) {
        console.log('insuranceData.spreadsheetData');
        fillErgebnisseTarifkalkulationPramie(this.priceData, this.insuranceData.spreadsheetData, this.insuranceData.basicData, this.$data);
        // fillpromotionalRewardsSB(this.promotionalRewards, this.pramienSelbstbehaltsvariantenItems, this.insuranceData.spreadsheetData.positionsdatenItems);
      }
    },
    pramienSelbstbehaltsvariantenItems: {
      handler(newValue, oldValue) {
        fillErgebnisseTarifkalkulationPramie(this.priceData, this.insuranceData.spreadsheetData, this.insuranceData.basicData, this.$data);

        // fillpromotionalRewardsSB(this.promotionalRewards, this.pramienSelbstbehaltsvariantenItems, this.insuranceData.spreadsheetData.positionsdatenItems);
      },
      deep: true,
    },
    skillDiscounts: {
      handler(newValue, oldValue) {
        fillErgebnisseTarifkalkulationPramie(this.priceData, this.insuranceData.spreadsheetData, this.insuranceData.basicData, this.$data);
        // fillpromotionalRewardsSB(this.promotionalRewards, this.pramienSelbstbehaltsvariantenItems, this.insuranceData.spreadsheetData.positionsdatenItems);
      },
      deep: true,
    },
  },
  methods: {
    getXmlBtnIsDisable(index: string) {
      if (this.AMSAPISendStatus !== null) {
        const status = this.AMSAPISendStatus[index];
        if (status !== null) {
          return (!status.dataUpdated && status.statusSuccess);
        }
      }
      return false;
    },
    onCurrencyFocus,
    fillSBValues(data, machineType) {
      let variant1SB = data.pramienSelbstbehaltsvariantenItems?.variant1?.SB;
      let variant2SB = data.pramienSelbstbehaltsvariantenItems?.variant2?.SB;

      if (machineType === MACHINE_KRAFTWERKE) {
        variant1SB = data.pramienSelbstbehaltsvariantenItems[0].SB;
        variant2SB = data.pramienSelbstbehaltsvariantenItems[1].SB;
      }

      if (data?.pramienSelbstbehaltsvariantenItems) {
        this.promotionalRewards[1].SB = variant1SB;
        this.promotionalRewards[2].SB = variant2SB;
        this.erstrisikosummenItems[1].SB = variant1SB;
        this.erstrisikosummenItems[2].SB = variant2SB;
        this.maschinenItems[1].SB = variant1SB;
        this.maschinenItems[2].SB = variant2SB;
      }
    },
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.priceFormData) {
        recursiveFillData(this.pramienSelbstbehaltsvariantenItems, this.insuranceFormsApiData.priceFormData.pramienSelbstbehaltsvariantenItems);
        recursiveFillData(this.zusammenfassung, this.insuranceFormsApiData.priceFormData.zusammenfassung);
      }
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Maschine-Preis-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 6,
        filename,
        html2canvas: {
          width: 1850,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
    goToBackUrl() {
      const routeName = getProductPageBeforePriceTabHelper(this.insuranceKey);
      this.$emit('go-back-tab', routeName, 'insuranceForms', 3);
    },
    setValues(priceFormData: any) {
      this.zusammenfassung = priceFormData.zusammenfassung;
      this.pramienSelbstbehaltsvariantenItems = priceFormData.pramienSelbstbehaltsvariantenItems;
      this.vorschadenhistorieSelect = priceFormData.vorschadenhistorieSelect;
      this.vorschadenhistorie = priceFormData.vorschadenhistorie;
      this.skillDiscounts = priceFormData.skillDiscounts;
      this.vierAugenPrinzip = priceFormData.vierAugenPrinzip;
      this.vierAugenPrinzip.date = new Date(priceFormData.vierAugenPrinzip.date);
      this.vertragAufMietumsatzbasis = priceFormData.vertragAufMietumsatzbasis;
      this.promotionalRewards = priceFormData.promotionalRewards;
      this.hinweiseZumBeitrag = priceFormData.hinweiseZumBeitrag;
    },
    onNextClick(redirectToNextPage = true) {
      this.vorschadenhistorie.summe.schaden = this.vorschadenhistorieSummeSchaden;
      this.vorschadenhistorie.summe.reserven = this.vorschadenhistorieSummeReserven;
      this.vorschadenhistorie.MindestjahrespramieTarifVariante = this.claimsRequirementPremium;

      const data = {
        zusammenfassung: this.zusammenfassung,
        pramienSelbstbehaltsvariantenItems: this.pramienSelbstbehaltsvariantenItems,
        vorschadenhistorieSelect: this.vorschadenhistorieSelect,
        vorschadenhistorie: this.vorschadenhistorie,
        skillDiscounts: this.skillDiscounts,
        vierAugenPrinzip: this.vierAugenPrinzip,
        vertragAufMietumsatzbasis: this.vertragAufMietumsatzbasis,
        promotionalRewards: this.promotionalRewards,
        hinweiseZumBeitrag: this.hinweiseZumBeitrag,
      };
      this.$emit('price-formData-changed', data);
    },
    queueDownloadPDF() {
      this.$emit('add-to-queue', 'downloadPDF');
      this.onNextClick();
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    queueDownloadBigXML(i) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onNextClick);
      this.$emit('add-to-queue', 'downloadBigXML', this.getVariantFromIndex(i));
      this.onNextClick();
    },
    queueSendBigXML(i) {
      saveZusammenfassungPDF.call(this, this.id, 'savePricePDF', this.onNextClick);
      this.$emit('add-to-queue', 'sendBigXML', this.getVariantFromIndex(i));
      this.onNextClick();
    },
    getVariantFromIndex(index) {
      switch (index) {
        case 0:
          return 'tarif';
        case 1:
          return 'variant1';
        case 2:
          return 'variant2';
        case 3:
          return 'variant3';
        default:
          return 'tarif';
      }
    },
    getProduktName(vertragsart) {
      switch (vertragsart) {
        case MACHINE_FAHRBAR:
          return 'Maschine fahrbar';
        case MACHINE_STATIONAR:
          return 'Maschine stationär';
        case MACHINE_KRAFTWERKE:
          return 'Kraftwerke';
        default:
          return '';
      }
    },
    gettarifSelbstbehaltsValue(key, value): string|number {
      if (key === 'Tarif SB') {
        return 'Tarif SB-Variante';
      }

      if (key === 'SB-Variante 3') {
        return 'Misch SB-Variante';
      }

      return value;
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceFormsApiData) {
      this.fillApiValuesData();
    }
    if (this.insuranceData.priceFormData) {
      this.setValues(this.insuranceData.priceFormData);
    }
    this.zusammenfassung.versicherungssummeAktuell = this.insuranceData.spreadsheetData.paramsVertragsdaten.sumInsured;
    this.zusammenfassung.versicherungssumme371 = this.insuranceData.spreadsheetData.paramsVertragsdaten.sum371;
    this.zusammenfassung.nettoisiert = this.insuranceData.spreadsheetData.paramsVertragsdaten.nettoisiert;
    this.zusammenfassung.produkt = this.getProduktName(this.insuranceData.basicData.vertragsart);
    fillErgebnisseTarifkalkulationPramie(this.priceData, this.insuranceData.spreadsheetData, this.insuranceData.basicData, this.$data);
    this.fillSBValues(this.insuranceData?.spreadsheetData, this.insuranceData?.basicData?.vertragsart);
    // fillpromotionalRewardsSB(this.promotionalRewards, this.pramienSelbstbehaltsvariantenItems, this.insuranceData.spreadsheetData.positionsdatenItems);
    // fillMaschinenSB(this.maschinenItems, this.pramienSelbstbehaltsvariantenItems, this.priceData);
    (this as any).startWatcherActivated();
  },
});

import { render, staticRenderFns } from "./bauleistung-price-card.vue?vue&type=template&id=25ba38b6&scoped=true&"
import script from "./bauleistung-price-card.vue?vue&type=script&lang=ts&"
export * from "./bauleistung-price-card.vue?vue&type=script&lang=ts&"
import style0 from "./bauleistung-price-card.vue?vue&type=style&index=0&id=25ba38b6&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ba38b6",
  null
  
)

export default component.exports